/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccessoryDto = {
    INFANT_CARRIER: 'INFANT_CARRIER',
    CHILD_SEAT: 'CHILD_SEAT',
    BOOSTER_CUSHION: 'BOOSTER_CUSHION',
    WATER_BOTTLE: 'WATER_BOTTLE',
    PICK_UP_SIGN: 'PICK_UP_SIGN'
} as const;
export type AccessoryDto = typeof AccessoryDto[keyof typeof AccessoryDto];


export function AccessoryDtoFromJSON(json: any): AccessoryDto {
    return AccessoryDtoFromJSONTyped(json, false);
}

export function AccessoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessoryDto {
    return json as AccessoryDto;
}

export function AccessoryDtoToJSON(value?: AccessoryDto | null): any {
    return value as any;
}

