/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountingIssuerDto } from './AccountingIssuerDto';
import {
    AccountingIssuerDtoFromJSON,
    AccountingIssuerDtoFromJSONTyped,
    AccountingIssuerDtoToJSON,
} from './AccountingIssuerDto';
import type { CollectiveOrderConfirmationRecipientDto } from './CollectiveOrderConfirmationRecipientDto';
import {
    CollectiveOrderConfirmationRecipientDtoFromJSON,
    CollectiveOrderConfirmationRecipientDtoFromJSONTyped,
    CollectiveOrderConfirmationRecipientDtoToJSON,
} from './CollectiveOrderConfirmationRecipientDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface CollectiveOrderConfirmationDto
 */
export interface CollectiveOrderConfirmationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CollectiveOrderConfirmationDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof CollectiveOrderConfirmationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {AccountingIssuerDto}
     * @memberof CollectiveOrderConfirmationDto
     */
    issuer: AccountingIssuerDto;
    /**
     * 
     * @type {CollectiveOrderConfirmationRecipientDto}
     * @memberof CollectiveOrderConfirmationDto
     */
    recipient: CollectiveOrderConfirmationRecipientDto;
    /**
     * 
     * @type {number}
     * @memberof CollectiveOrderConfirmationDto
     */
    internalPaymentTerm: number;
}

/**
 * Check if a given object implements the CollectiveOrderConfirmationDto interface.
 */
export function instanceOfCollectiveOrderConfirmationDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('issuer' in value)) return false;
    if (!('recipient' in value)) return false;
    if (!('internalPaymentTerm' in value)) return false;
    return true;
}

export function CollectiveOrderConfirmationDtoFromJSON(json: any): CollectiveOrderConfirmationDto {
    return CollectiveOrderConfirmationDtoFromJSONTyped(json, false);
}

export function CollectiveOrderConfirmationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectiveOrderConfirmationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'issuer': AccountingIssuerDtoFromJSON(json['issuer']),
        'recipient': CollectiveOrderConfirmationRecipientDtoFromJSON(json['recipient']),
        'internalPaymentTerm': json['internalPaymentTerm'],
    };
}

export function CollectiveOrderConfirmationDtoToJSON(value?: CollectiveOrderConfirmationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'issuer': AccountingIssuerDtoToJSON(value['issuer']),
        'recipient': CollectiveOrderConfirmationRecipientDtoToJSON(value['recipient']),
        'internalPaymentTerm': value['internalPaymentTerm'],
    };
}

