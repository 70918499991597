/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessibilityDto } from './AccessibilityDto';
import {
    AccessibilityDtoFromJSON,
    AccessibilityDtoFromJSONTyped,
    AccessibilityDtoToJSON,
} from './AccessibilityDto';
import type { AccessoryItemDto } from './AccessoryItemDto';
import {
    AccessoryItemDtoFromJSON,
    AccessoryItemDtoFromJSONTyped,
    AccessoryItemDtoToJSON,
} from './AccessoryItemDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';
import type { GroupReferenceDto } from './GroupReferenceDto';
import {
    GroupReferenceDtoFromJSON,
    GroupReferenceDtoFromJSONTyped,
    GroupReferenceDtoToJSON,
} from './GroupReferenceDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { ReasonForJourneyDto } from './ReasonForJourneyDto';
import {
    ReasonForJourneyDtoFromJSON,
    ReasonForJourneyDtoFromJSONTyped,
    ReasonForJourneyDtoToJSON,
} from './ReasonForJourneyDto';
import type { StaffReferenceDto } from './StaffReferenceDto';
import {
    StaffReferenceDtoFromJSON,
    StaffReferenceDtoFromJSONTyped,
    StaffReferenceDtoToJSON,
} from './StaffReferenceDto';
import type { StopoverDto } from './StopoverDto';
import {
    StopoverDtoFromJSON,
    StopoverDtoFromJSONTyped,
    StopoverDtoToJSON,
} from './StopoverDto';
import type { VehicleBookingStatusDto } from './VehicleBookingStatusDto';
import {
    VehicleBookingStatusDtoFromJSON,
    VehicleBookingStatusDtoFromJSONTyped,
    VehicleBookingStatusDtoToJSON,
} from './VehicleBookingStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Booking of a vehicle for the transportation of guests or film teams
 * @export
 * @interface VehicleBookingDto
 */
export interface VehicleBookingDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof VehicleBookingDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof VehicleBookingDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof VehicleBookingDto
     */
    edition: EditionReferenceDto;
    /**
     * The unique key for a vehicle booking
     * @type {string}
     * @memberof VehicleBookingDto
     */
    transactionNumber: string;
    /**
     * 
     * @type {StaffReferenceDto}
     * @memberof VehicleBookingDto
     */
    principal: StaffReferenceDto;
    /**
     * Guest relation managers who deal with the guest.
     * @type {Array<StaffReferenceDto>}
     * @memberof VehicleBookingDto
     */
    involvedParties?: Array<StaffReferenceDto>;
    /**
     * 
     * @type {StaffReferenceDto}
     * @memberof VehicleBookingDto
     */
    localContact?: StaffReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof VehicleBookingDto
     */
    pickUpTime: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleBookingDto
     */
    startingPoint?: string;
    /**
     * 
     * @type {Array<StopoverDto>}
     * @memberof VehicleBookingDto
     */
    stopovers?: Array<StopoverDto>;
    /**
     * 
     * @type {Date}
     * @memberof VehicleBookingDto
     */
    dropOffTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleBookingDto
     */
    destination?: string;
    /**
     * 
     * @type {ReasonForJourneyDto}
     * @memberof VehicleBookingDto
     */
    reasonForJourney: ReasonForJourneyDto;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof VehicleBookingDto
     */
    event?: EventReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleBookingDto
     */
    travelDetails?: string;
    /**
     * 
     * @type {AccessibilityDto}
     * @memberof VehicleBookingDto
     */
    accessibility: AccessibilityDto;
    /**
     * 
     * @type {Array<AccessoryItemDto>}
     * @memberof VehicleBookingDto
     */
    accessories?: Array<AccessoryItemDto>;
    /**
     * 
     * @type {Array<PersonReferenceDto>}
     * @memberof VehicleBookingDto
     */
    personPassengers?: Array<PersonReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof VehicleBookingDto
     */
    customPassengers?: string;
    /**
     * 
     * @type {Array<GroupReferenceDto>}
     * @memberof VehicleBookingDto
     */
    groups?: Array<GroupReferenceDto>;
    /**
     * 
     * @type {number}
     * @memberof VehicleBookingDto
     */
    numberOfPassengers: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleBookingDto
     */
    comment?: string;
    /**
     * 
     * @type {VehicleBookingStatusDto}
     * @memberof VehicleBookingDto
     */
    status?: VehicleBookingStatusDto;
}

/**
 * Check if a given object implements the VehicleBookingDto interface.
 */
export function instanceOfVehicleBookingDto(value: object): boolean {
    if (!('edition' in value)) return false;
    if (!('transactionNumber' in value)) return false;
    if (!('principal' in value)) return false;
    if (!('pickUpTime' in value)) return false;
    if (!('reasonForJourney' in value)) return false;
    if (!('accessibility' in value)) return false;
    if (!('numberOfPassengers' in value)) return false;
    return true;
}

export function VehicleBookingDtoFromJSON(json: any): VehicleBookingDto {
    return VehicleBookingDtoFromJSONTyped(json, false);
}

export function VehicleBookingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleBookingDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'transactionNumber': json['transactionNumber'],
        'principal': StaffReferenceDtoFromJSON(json['principal']),
        'involvedParties': json['involvedParties'] == null ? undefined : ((json['involvedParties'] as Array<any>).map(StaffReferenceDtoFromJSON)),
        'localContact': json['localContact'] == null ? undefined : StaffReferenceDtoFromJSON(json['localContact']),
        'pickUpTime': (new Date(json['pickUpTime'])),
        'startingPoint': json['startingPoint'] == null ? undefined : json['startingPoint'],
        'stopovers': json['stopovers'] == null ? undefined : ((json['stopovers'] as Array<any>).map(StopoverDtoFromJSON)),
        'dropOffTime': json['dropOffTime'] == null ? undefined : (new Date(json['dropOffTime'])),
        'destination': json['destination'] == null ? undefined : json['destination'],
        'reasonForJourney': ReasonForJourneyDtoFromJSON(json['reasonForJourney']),
        'event': json['event'] == null ? undefined : EventReferenceDtoFromJSON(json['event']),
        'travelDetails': json['travelDetails'] == null ? undefined : json['travelDetails'],
        'accessibility': AccessibilityDtoFromJSON(json['accessibility']),
        'accessories': json['accessories'] == null ? undefined : ((json['accessories'] as Array<any>).map(AccessoryItemDtoFromJSON)),
        'personPassengers': json['personPassengers'] == null ? undefined : ((json['personPassengers'] as Array<any>).map(PersonReferenceDtoFromJSON)),
        'customPassengers': json['customPassengers'] == null ? undefined : json['customPassengers'],
        'groups': json['groups'] == null ? undefined : ((json['groups'] as Array<any>).map(GroupReferenceDtoFromJSON)),
        'numberOfPassengers': json['numberOfPassengers'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'status': json['status'] == null ? undefined : VehicleBookingStatusDtoFromJSON(json['status']),
    };
}

export function VehicleBookingDtoToJSON(value?: VehicleBookingDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'transactionNumber': value['transactionNumber'],
        'principal': StaffReferenceDtoToJSON(value['principal']),
        'involvedParties': value['involvedParties'] == null ? undefined : ((value['involvedParties'] as Array<any>).map(StaffReferenceDtoToJSON)),
        'localContact': StaffReferenceDtoToJSON(value['localContact']),
        'pickUpTime': ((value['pickUpTime']).toISOString()),
        'startingPoint': value['startingPoint'],
        'stopovers': value['stopovers'] == null ? undefined : ((value['stopovers'] as Array<any>).map(StopoverDtoToJSON)),
        'dropOffTime': value['dropOffTime'] == null ? undefined : ((value['dropOffTime']).toISOString()),
        'destination': value['destination'],
        'reasonForJourney': ReasonForJourneyDtoToJSON(value['reasonForJourney']),
        'event': EventReferenceDtoToJSON(value['event']),
        'travelDetails': value['travelDetails'],
        'accessibility': AccessibilityDtoToJSON(value['accessibility']),
        'accessories': value['accessories'] == null ? undefined : ((value['accessories'] as Array<any>).map(AccessoryItemDtoToJSON)),
        'personPassengers': value['personPassengers'] == null ? undefined : ((value['personPassengers'] as Array<any>).map(PersonReferenceDtoToJSON)),
        'customPassengers': value['customPassengers'],
        'groups': value['groups'] == null ? undefined : ((value['groups'] as Array<any>).map(GroupReferenceDtoToJSON)),
        'numberOfPassengers': value['numberOfPassengers'],
        'comment': value['comment'],
        'status': VehicleBookingStatusDtoToJSON(value['status']),
    };
}

