/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountingIssuerDto } from './AccountingIssuerDto';
import {
    AccountingIssuerDtoFromJSON,
    AccountingIssuerDtoFromJSONTyped,
    AccountingIssuerDtoToJSON,
} from './AccountingIssuerDto';
import type { CollectiveOrderConfirmationRecipientDto } from './CollectiveOrderConfirmationRecipientDto';
import {
    CollectiveOrderConfirmationRecipientDtoFromJSON,
    CollectiveOrderConfirmationRecipientDtoFromJSONTyped,
    CollectiveOrderConfirmationRecipientDtoToJSON,
} from './CollectiveOrderConfirmationRecipientDto';
import type { CollectiveOrderConfirmationStatusDto } from './CollectiveOrderConfirmationStatusDto';
import {
    CollectiveOrderConfirmationStatusDtoFromJSON,
    CollectiveOrderConfirmationStatusDtoFromJSONTyped,
    CollectiveOrderConfirmationStatusDtoToJSON,
} from './CollectiveOrderConfirmationStatusDto';
import type { PaymentTargetDto } from './PaymentTargetDto';
import {
    PaymentTargetDtoFromJSON,
    PaymentTargetDtoFromJSONTyped,
    PaymentTargetDtoToJSON,
} from './PaymentTargetDto';
import type { SumTotalDto } from './SumTotalDto';
import {
    SumTotalDtoFromJSON,
    SumTotalDtoFromJSONTyped,
    SumTotalDtoToJSON,
} from './SumTotalDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface CollectiveOrderConfirmationViewerDto
 */
export interface CollectiveOrderConfirmationViewerDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {AccountingIssuerDto}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    issuer: AccountingIssuerDto;
    /**
     * 
     * @type {CollectiveOrderConfirmationRecipientDto}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    recipient: CollectiveOrderConfirmationRecipientDto;
    /**
     * 
     * @type {number}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    internalPaymentTerm: number;
    /**
     * 
     * @type {CollectiveOrderConfirmationStatusDto}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    status: CollectiveOrderConfirmationStatusDto;
    /**
     * 
     * @type {SumTotalDto}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    sumTotal: SumTotalDto;
    /**
     * 
     * @type {string}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    collectiveOrderConfirmationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    collectiveOrderConfirmationDate: string;
    /**
     * 
     * @type {Array<PaymentTargetDto>}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    paymentPlan: Array<PaymentTargetDto>;
    /**
     * 
     * @type {Date}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    sentAt?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    sentToEmails: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CollectiveOrderConfirmationViewerDto
     */
    paymentLink?: string;
}

/**
 * Check if a given object implements the CollectiveOrderConfirmationViewerDto interface.
 */
export function instanceOfCollectiveOrderConfirmationViewerDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('issuer' in value)) return false;
    if (!('recipient' in value)) return false;
    if (!('internalPaymentTerm' in value)) return false;
    if (!('status' in value)) return false;
    if (!('sumTotal' in value)) return false;
    if (!('collectiveOrderConfirmationNumber' in value)) return false;
    if (!('collectiveOrderConfirmationDate' in value)) return false;
    if (!('paymentPlan' in value)) return false;
    if (!('sentToEmails' in value)) return false;
    return true;
}

export function CollectiveOrderConfirmationViewerDtoFromJSON(json: any): CollectiveOrderConfirmationViewerDto {
    return CollectiveOrderConfirmationViewerDtoFromJSONTyped(json, false);
}

export function CollectiveOrderConfirmationViewerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectiveOrderConfirmationViewerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'issuer': AccountingIssuerDtoFromJSON(json['issuer']),
        'recipient': CollectiveOrderConfirmationRecipientDtoFromJSON(json['recipient']),
        'internalPaymentTerm': json['internalPaymentTerm'],
        'status': CollectiveOrderConfirmationStatusDtoFromJSON(json['status']),
        'sumTotal': SumTotalDtoFromJSON(json['sumTotal']),
        'collectiveOrderConfirmationNumber': json['collectiveOrderConfirmationNumber'],
        'collectiveOrderConfirmationDate': json['collectiveOrderConfirmationDate'],
        'paymentPlan': ((json['paymentPlan'] as Array<any>).map(PaymentTargetDtoFromJSON)),
        'sentAt': json['sentAt'] == null ? undefined : (new Date(json['sentAt'])),
        'sentToEmails': json['sentToEmails'],
        'paymentLink': json['paymentLink'] == null ? undefined : json['paymentLink'],
    };
}

export function CollectiveOrderConfirmationViewerDtoToJSON(value?: CollectiveOrderConfirmationViewerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'issuer': AccountingIssuerDtoToJSON(value['issuer']),
        'recipient': CollectiveOrderConfirmationRecipientDtoToJSON(value['recipient']),
        'internalPaymentTerm': value['internalPaymentTerm'],
        'status': CollectiveOrderConfirmationStatusDtoToJSON(value['status']),
        'sumTotal': SumTotalDtoToJSON(value['sumTotal']),
        'collectiveOrderConfirmationNumber': value['collectiveOrderConfirmationNumber'],
        'collectiveOrderConfirmationDate': value['collectiveOrderConfirmationDate'],
        'paymentPlan': ((value['paymentPlan'] as Array<any>).map(PaymentTargetDtoToJSON)),
        'sentAt': value['sentAt'] == null ? undefined : ((value['sentAt']).toISOString()),
        'sentToEmails': value['sentToEmails'],
        'paymentLink': value['paymentLink'],
    };
}

