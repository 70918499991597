/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountingCreationTypeDto } from './AccountingCreationTypeDto';
import {
    AccountingCreationTypeDtoFromJSON,
    AccountingCreationTypeDtoFromJSONTyped,
    AccountingCreationTypeDtoToJSON,
} from './AccountingCreationTypeDto';
import type { AccountingIssuerDto } from './AccountingIssuerDto';
import {
    AccountingIssuerDtoFromJSON,
    AccountingIssuerDtoFromJSONTyped,
    AccountingIssuerDtoToJSON,
} from './AccountingIssuerDto';
import type { AccountingPaymentStatusDto } from './AccountingPaymentStatusDto';
import {
    AccountingPaymentStatusDtoFromJSON,
    AccountingPaymentStatusDtoFromJSONTyped,
    AccountingPaymentStatusDtoToJSON,
} from './AccountingPaymentStatusDto';
import type { AccountingRecipientDto } from './AccountingRecipientDto';
import {
    AccountingRecipientDtoFromJSON,
    AccountingRecipientDtoFromJSONTyped,
    AccountingRecipientDtoToJSON,
} from './AccountingRecipientDto';
import type { OrderConfirmationPaymentResultDto } from './OrderConfirmationPaymentResultDto';
import {
    OrderConfirmationPaymentResultDtoFromJSON,
    OrderConfirmationPaymentResultDtoFromJSONTyped,
    OrderConfirmationPaymentResultDtoToJSON,
} from './OrderConfirmationPaymentResultDto';
import type { OrderConfirmationStatusDto } from './OrderConfirmationStatusDto';
import {
    OrderConfirmationStatusDtoFromJSON,
    OrderConfirmationStatusDtoFromJSONTyped,
    OrderConfirmationStatusDtoToJSON,
} from './OrderConfirmationStatusDto';
import type { SumTotalDto } from './SumTotalDto';
import {
    SumTotalDtoFromJSON,
    SumTotalDtoFromJSONTyped,
    SumTotalDtoToJSON,
} from './SumTotalDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface OrderConfirmationListItemDto
 */
export interface OrderConfirmationListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof OrderConfirmationListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationListItemDto
     */
    orderConfirmationNumber: string;
    /**
     * 
     * @type {OrderConfirmationStatusDto}
     * @memberof OrderConfirmationListItemDto
     */
    status: OrderConfirmationStatusDto;
    /**
     * 
     * @type {AccountingPaymentStatusDto}
     * @memberof OrderConfirmationListItemDto
     */
    paymentStatus?: AccountingPaymentStatusDto;
    /**
     * 
     * @type {SumTotalDto}
     * @memberof OrderConfirmationListItemDto
     */
    sumTotal: SumTotalDto;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationListItemDto
     */
    orderConfirmationDate: string;
    /**
     * 
     * @type {AccountingIssuerDto}
     * @memberof OrderConfirmationListItemDto
     */
    issuer: AccountingIssuerDto;
    /**
     * 
     * @type {boolean}
     * @memberof OrderConfirmationListItemDto
     */
    documentGenerated: boolean;
    /**
     * 
     * @type {AccountingRecipientDto}
     * @memberof OrderConfirmationListItemDto
     */
    recipient: AccountingRecipientDto;
    /**
     * 
     * @type {AccountingCreationTypeDto}
     * @memberof OrderConfirmationListItemDto
     */
    creationType: AccountingCreationTypeDto;
    /**
     * 
     * @type {OrderConfirmationPaymentResultDto}
     * @memberof OrderConfirmationListItemDto
     */
    paymentResult?: OrderConfirmationPaymentResultDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof OrderConfirmationListItemDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the OrderConfirmationListItemDto interface.
 */
export function instanceOfOrderConfirmationListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('orderConfirmationNumber' in value)) return false;
    if (!('status' in value)) return false;
    if (!('sumTotal' in value)) return false;
    if (!('orderConfirmationDate' in value)) return false;
    if (!('issuer' in value)) return false;
    if (!('documentGenerated' in value)) return false;
    if (!('recipient' in value)) return false;
    if (!('creationType' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function OrderConfirmationListItemDtoFromJSON(json: any): OrderConfirmationListItemDto {
    return OrderConfirmationListItemDtoFromJSONTyped(json, false);
}

export function OrderConfirmationListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderConfirmationListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderConfirmationNumber': json['orderConfirmationNumber'],
        'status': OrderConfirmationStatusDtoFromJSON(json['status']),
        'paymentStatus': json['paymentStatus'] == null ? undefined : AccountingPaymentStatusDtoFromJSON(json['paymentStatus']),
        'sumTotal': SumTotalDtoFromJSON(json['sumTotal']),
        'orderConfirmationDate': json['orderConfirmationDate'],
        'issuer': AccountingIssuerDtoFromJSON(json['issuer']),
        'documentGenerated': json['documentGenerated'],
        'recipient': AccountingRecipientDtoFromJSON(json['recipient']),
        'creationType': AccountingCreationTypeDtoFromJSON(json['creationType']),
        'paymentResult': json['paymentResult'] == null ? undefined : OrderConfirmationPaymentResultDtoFromJSON(json['paymentResult']),
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function OrderConfirmationListItemDtoToJSON(value?: OrderConfirmationListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'orderConfirmationNumber': value['orderConfirmationNumber'],
        'status': OrderConfirmationStatusDtoToJSON(value['status']),
        'paymentStatus': AccountingPaymentStatusDtoToJSON(value['paymentStatus']),
        'sumTotal': SumTotalDtoToJSON(value['sumTotal']),
        'orderConfirmationDate': value['orderConfirmationDate'],
        'issuer': AccountingIssuerDtoToJSON(value['issuer']),
        'documentGenerated': value['documentGenerated'],
        'recipient': AccountingRecipientDtoToJSON(value['recipient']),
        'creationType': AccountingCreationTypeDtoToJSON(value['creationType']),
        'paymentResult': OrderConfirmationPaymentResultDtoToJSON(value['paymentResult']),
        'version': VersionDtoToJSON(value['version']),
    };
}

