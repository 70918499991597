/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResultCountDto
 */
export interface ResultCountDto {
    /**
     * 
     * @type {string}
     * @memberof ResultCountDto
     */
    resultType: ResultCountDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ResultCountDto
     */
    count: number;
}


/**
 * @export
 */
export const ResultCountDtoResultTypeEnum = {
    ACCESS_GROUP: 'ACCESS_GROUP',
    ACCOUNT: 'ACCOUNT',
    AWARD: 'AWARD',
    BLOCKLIST: 'BLOCKLIST',
    CODE: 'CODE',
    COLLECTIVE_ORDER_CONFIRMATION: 'COLLECTIVE_ORDER_CONFIRMATION',
    COMPANY: 'COMPANY',
    COUNTRY: 'COUNTRY',
    EDITION: 'EDITION',
    EVENT: 'EVENT',
    EVENT_EVALUATION: 'EVENT_EVALUATION',
    FILM: 'FILM',
    FORMAT: 'FORMAT',
    GROUP: 'GROUP',
    GROUP_TEMPLATE: 'GROUP_TEMPLATE',
    GUESTLIST: 'GUESTLIST',
    KEY_PLAYER: 'KEY_PLAYER',
    INVOICE: 'INVOICE',
    LANDING_PAGE: 'LANDING_PAGE',
    LANDING_PAGE_DESIGN: 'LANDING_PAGE_DESIGN',
    LANDING_PAGE_TEMPLATE: 'LANDING_PAGE_TEMPLATE',
    LIST: 'LIST',
    LOCATION: 'LOCATION',
    MAILING: 'MAILING',
    MAILING_DESIGN: 'MAILING_DESIGN',
    MAILING_LIST: 'MAILING_LIST',
    MAILING_TEMPLATE: 'MAILING_TEMPLATE',
    OFFER: 'OFFER',
    ORDER: 'ORDER',
    ORDERCONFIRMATION: 'ORDERCONFIRMATION',
    PARTICIPATION: 'PARTICIPATION',
    PERSON: 'PERSON',
    PLACEMENT_TEMPLATE: 'PLACEMENT_TEMPLATE',
    PRODUCT: 'PRODUCT',
    PRODUCT_BUNDLE: 'PRODUCT_BUNDLE',
    RUNDOWN_LANE: 'RUNDOWN_LANE',
    RUNDOWN_LANE_ELEMENT: 'RUNDOWN_LANE_ELEMENT',
    SECTION: 'SECTION',
    STAFF: 'STAFF',
    STAND: 'STAND',
    SYSTEM_MAILING: 'SYSTEM_MAILING',
    TAG: 'TAG',
    TEAM: 'TEAM',
    VEHICLE_BOOKING: 'VEHICLE_BOOKING',
    VISA_TEMPLATE: 'VISA_TEMPLATE',
    VISA: 'VISA'
} as const;
export type ResultCountDtoResultTypeEnum = typeof ResultCountDtoResultTypeEnum[keyof typeof ResultCountDtoResultTypeEnum];


/**
 * Check if a given object implements the ResultCountDto interface.
 */
export function instanceOfResultCountDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('count' in value)) return false;
    return true;
}

export function ResultCountDtoFromJSON(json: any): ResultCountDto {
    return ResultCountDtoFromJSONTyped(json, false);
}

export function ResultCountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultCountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'count': json['count'],
    };
}

export function ResultCountDtoToJSON(value?: ResultCountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'count': value['count'],
    };
}

