/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  PageableDto,
  VehicleBookingDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    VehicleBookingDtoFromJSON,
    VehicleBookingDtoToJSON,
} from '../models/index';

export interface CreateVehicleBookingRequest {
    vehicleBookingDto: VehicleBookingDto;
}

export interface FetchVehicleBookingRequest {
    id: string;
}

export interface FetchVehicleBookingHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface UpdateVehicleBookingRequest {
    id: string;
    vehicleBookingDto: VehicleBookingDto;
}

/**
 * 
 */
export class VehicleBookingApi extends runtime.BaseAPI {

    /**
     * Create a new vehicle booking.
     */
    async createVehicleBookingRaw(requestParameters: CreateVehicleBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['vehicleBookingDto'] == null) {
            throw new runtime.RequiredError(
                'vehicleBookingDto',
                'Required parameter "vehicleBookingDto" was null or undefined when calling createVehicleBooking().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle-bookings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleBookingDtoToJSON(requestParameters['vehicleBookingDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new vehicle booking.
     */
    async createVehicleBooking(requestParameters: CreateVehicleBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createVehicleBookingRaw(requestParameters, initOverrides);
    }

    /**
     * Get the vehicle booking business object.
     */
    async fetchVehicleBookingRaw(requestParameters: FetchVehicleBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleBookingDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchVehicleBooking().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle-bookings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleBookingDtoFromJSON(jsonValue));
    }

    /**
     * Get the vehicle booking business object.
     */
    async fetchVehicleBooking(requestParameters: FetchVehicleBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleBookingDto> {
        const response = await this.fetchVehicleBookingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the vehicle booking.
     */
    async fetchVehicleBookingHistoryRaw(requestParameters: FetchVehicleBookingHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchVehicleBookingHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle-bookings/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the vehicle booking.
     */
    async fetchVehicleBookingHistory(requestParameters: FetchVehicleBookingHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchVehicleBookingHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new vehicle booking.
     */
    async templateVehicleBookingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleBookingDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle-bookings/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleBookingDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new vehicle booking.
     */
    async templateVehicleBooking(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleBookingDto> {
        const response = await this.templateVehicleBookingRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing vehicle booking.
     */
    async updateVehicleBookingRaw(requestParameters: UpdateVehicleBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateVehicleBooking().'
            );
        }

        if (requestParameters['vehicleBookingDto'] == null) {
            throw new runtime.RequiredError(
                'vehicleBookingDto',
                'Required parameter "vehicleBookingDto" was null or undefined when calling updateVehicleBooking().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle-bookings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleBookingDtoToJSON(requestParameters['vehicleBookingDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing vehicle booking.
     */
    async updateVehicleBooking(requestParameters: UpdateVehicleBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateVehicleBookingRaw(requestParameters, initOverrides);
    }

}
