import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import VehicleBookingBreadcrumb from './common/vehicle-booking-breadcrumb';

const vehicleBookingRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'vehicle-bookings',
    handle: {
      breadcrumb: <Translate ns="vehicle_booking" i18nKey="vehicleBooking" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./vehicle-booking-lister/vehicle-booking-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./vehicle-booking-editor/new-vehicle-booking-route'),
      },
      {
        path: ':vehicleBookingId',
        handle: {
          breadcrumb: <VehicleBookingBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./vehicle-booking-page/vehicle-booking-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./vehicle-booking-viewer/vehicle-booking-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./vehicle-booking-history/vehicle-booking-history-route'),
              },
            ],
          },
        ],
      },
    ],
  },
];

export default vehicleBookingRoutes;
