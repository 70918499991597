/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FailureDto,
  HistoryPageDto,
  LocationAddressPageDto,
  LocationCardDto,
  LocationDto,
  LocationPageDto,
  LocationStatusCheckDto,
  PageableDto,
  ValidateUniqueProperties200ResponseDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    LocationAddressPageDtoFromJSON,
    LocationAddressPageDtoToJSON,
    LocationCardDtoFromJSON,
    LocationCardDtoToJSON,
    LocationDtoFromJSON,
    LocationDtoToJSON,
    LocationPageDtoFromJSON,
    LocationPageDtoToJSON,
    LocationStatusCheckDtoFromJSON,
    LocationStatusCheckDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    ValidateUniqueProperties200ResponseDtoFromJSON,
    ValidateUniqueProperties200ResponseDtoToJSON,
} from '../models/index';

export interface CheckLocationStatusRequest {
    locationId: string;
}

export interface CreateLocationRequest {
    locationDto: LocationDto;
}

export interface DeleteLocationRequest {
    id: string;
}

export interface FetchLocationRequest {
    id: string;
}

export interface FetchLocationCardRequest {
    locationId: string;
}

export interface FetchLocationHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchLocationAddressesRequest {
    pageable?: PageableDto;
    searchquery?: string;
}

export interface SearchLocationsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateLocationRequest {
    id: string;
    locationDto: LocationDto;
}

export interface ValidateLinkRequest {
    url: string;
}

export interface ValidateUniquePropertiesRequest {
    name?: string;
    englishName?: string;
    abbreviation?: string;
}

/**
 * 
 */
export class LocationApi extends runtime.BaseAPI {

    /**
     * Get the current status of the location.
     */
    async checkLocationStatusRaw(requestParameters: CheckLocationStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationStatusCheckDto>> {
        if (requestParameters['locationId'] == null) {
            throw new runtime.RequiredError(
                'locationId',
                'Required parameter "locationId" was null or undefined when calling checkLocationStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{locationId}/check-status`.replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters['locationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationStatusCheckDtoFromJSON(jsonValue));
    }

    /**
     * Get the current status of the location.
     */
    async checkLocationStatus(requestParameters: CheckLocationStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationStatusCheckDto> {
        const response = await this.checkLocationStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new location.
     */
    async createLocationRaw(requestParameters: CreateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationDto>> {
        if (requestParameters['locationDto'] == null) {
            throw new runtime.RequiredError(
                'locationDto',
                'Required parameter "locationDto" was null or undefined when calling createLocation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LocationDtoToJSON(requestParameters['locationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationDtoFromJSON(jsonValue));
    }

    /**
     * Create a new location.
     */
    async createLocation(requestParameters: CreateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationDto> {
        const response = await this.createLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the location.
     */
    async deleteLocationRaw(requestParameters: DeleteLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteLocation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the location.
     */
    async deleteLocation(requestParameters: DeleteLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLocationRaw(requestParameters, initOverrides);
    }

    /**
     * Get the location business object.
     */
    async fetchLocationRaw(requestParameters: FetchLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchLocation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationDtoFromJSON(jsonValue));
    }

    /**
     * Get the location business object.
     */
    async fetchLocation(requestParameters: FetchLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationDto> {
        const response = await this.fetchLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the location short infos. Will be shown while hovering over a location reference.
     */
    async fetchLocationCardRaw(requestParameters: FetchLocationCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationCardDto>> {
        if (requestParameters['locationId'] == null) {
            throw new runtime.RequiredError(
                'locationId',
                'Required parameter "locationId" was null or undefined when calling fetchLocationCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{locationId}/card`.replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters['locationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationCardDtoFromJSON(jsonValue));
    }

    /**
     * Get the location short infos. Will be shown while hovering over a location reference.
     */
    async fetchLocationCard(requestParameters: FetchLocationCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationCardDto> {
        const response = await this.fetchLocationCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the location.
     */
    async fetchLocationHistoryRaw(requestParameters: FetchLocationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchLocationHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the location.
     */
    async fetchLocationHistory(requestParameters: FetchLocationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchLocationHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for location addresses
     */
    async searchLocationAddressesRaw(requestParameters: SearchLocationAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationAddressPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['searchquery'] != null) {
            queryParameters['searchquery'] = requestParameters['searchquery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationAddressPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for location addresses
     */
    async searchLocationAddresses(requestParameters: SearchLocationAddressesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationAddressPageDto> {
        const response = await this.searchLocationAddressesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for locations.
     */
    async searchLocationsRaw(requestParameters: SearchLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for locations.
     */
    async searchLocations(requestParameters: SearchLocationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationPageDto> {
        const response = await this.searchLocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new location.
     */
    async templateLocationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new location.
     */
    async templateLocation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationDto> {
        const response = await this.templateLocationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing location.
     */
    async updateLocationRaw(requestParameters: UpdateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateLocation().'
            );
        }

        if (requestParameters['locationDto'] == null) {
            throw new runtime.RequiredError(
                'locationDto',
                'Required parameter "locationDto" was null or undefined when calling updateLocation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LocationDtoToJSON(requestParameters['locationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing location.
     */
    async updateLocation(requestParameters: UpdateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationDto> {
        const response = await this.updateLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the validation result for a link.
     */
    async validateLinkRaw(requestParameters: ValidateLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['url'] == null) {
            throw new runtime.RequiredError(
                'url',
                'Required parameter "url" was null or undefined when calling validateLink().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['url'] != null) {
            queryParameters['url'] = requestParameters['url'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/is-valid-link`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get the validation result for a link.
     */
    async validateLink(requestParameters: ValidateLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.validateLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check whether any of the queryParam properties are unique
     */
    async validateUniquePropertiesRaw(requestParameters: ValidateUniquePropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateUniqueProperties200ResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['englishName'] != null) {
            queryParameters['englishName'] = requestParameters['englishName'];
        }

        if (requestParameters['abbreviation'] != null) {
            queryParameters['abbreviation'] = requestParameters['abbreviation'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/validate-unique-properties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateUniqueProperties200ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Check whether any of the queryParam properties are unique
     */
    async validateUniqueProperties(requestParameters: ValidateUniquePropertiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateUniqueProperties200ResponseDto> {
        const response = await this.validateUniquePropertiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
