import { collectiveOrderConfirmationIcon } from '../../../ui/icons/business-objects';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import { SEARCH_FILTER } from '../../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../../search/search-plugin';
import CollectiveOrderConfirmationSearchResult from './collective-order-confirmation-search-result';

export default function CollectiveOrderConfirmationPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'COLLECTIVE_ORDER_CONFIRMATION',
    Component: CollectiveOrderConfirmationSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'COLLECTIVE_ORDER_CONFIRMATION',
    icon: collectiveOrderConfirmationIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.COLLECTIVE_ORDER_CONFIRMATION" />,
    // TODO: BX-5357 S-AUF-Rechte?
    necessaryPermission: 'ORDER_CONFIRMATION:CAN_SEE_DETAILS',
  });
}
